<template lang="pug">
  div.login-container(@keyup.enter.native="handleLogin")
    div.bg-img
    div.login-box.animated.bounceInDown
        div.login-left
            div.login-time 欢迎使用
            img.img(src="../../assets/logo.png")
            p.title  Join & Joy管理后台
        div.login-border
            div.login-main
                h4.login-title  管理员登录
                el-form(status-icon
                        ref="loginForm"
                        :model="loginForm"
                        label-width="0").login-form
                    el-form-item
                        el-input(v-model="loginForm.username"
                                placeholder="请输入用户名"
                                size="small"
                                auto-complete="off"
                                prefix-icon="el-icon-user"
                                @keyup.enter.native="handleLogin")
                    el-form-item
                        el-input(v-model="loginForm.password"
                                placeholder="请输入密码"
                                size="small"
                                auto-complete="off"
                                show-password
                                prefix-icon="el-icon-key"
                                @keyup.enter.native="handleLogin")
                    el-form-item
                        el-button(type="primary"
                                size="small"
                                :loading="loading"
                                @click.native.prevent="handleLogin").login-submit 登录
        el-divider(direction="vertical").login-divider



</template>

<script>
export default {
  data: () => ({
    loginForm: {
      username: "",
      password: ""
    },
    loading: false
  }),
  methods: {
    async handleLogin() {
      this.loading = true;
      try {
        await new Promise(r => setTimeout(r, 500));
        await this.$store.dispatch("LoginByUsername", this.loginForm);
        this.$router.push({ path: this.redirect || "/" });
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss">
.login-divider {
  position: absolute;
  left: 50%;
  top: 20%;
  width: 1px;
  height: 60%;
}

.bg-img {
  background: #00449f;
  //background: rgba(64, 158, 255, 0.5);
  // background-image: linear-gradient(rgba(64, 158, 255, 0.5), url(../assets/logo.png));
  // background: rgba(255, 0, 0, 1) url(../../assets/bg-img.png);
  width: 100%;
  height: 400px;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
}

.login-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.login-box {
  margin: 0 auto;
  position: relative;
  width: 1000px;
  box-shadow: -4px 5px 10px rgba(0, 0, 0, 0.4);
  .el-input-group__append {
    border: none;
  }
}

.login-left,
.login-border {
  position: relative;
  min-height: 500px;
  align-items: center;
  display: flex;
}
.login-left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  justify-content: center;
  flex-direction: column;
  background-color: white;

  // color: #fff;
  float: left;
  width: 50%;
  position: relative;
}
.login-left .img {
  width: 140px;
}
.login-time {
  position: absolute;
  text-align: left;
  left: 25px;
  top: 25px;
  width: 100%;
  // color: #fff;
  font-weight: 200;
  opacity: 0.9;
  font-size: 18px;
  overflow: hidden;
}
.login-left .title {
  margin-top: 60px;
  text-align: center;
  // color: #fff;
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 25px;
}

.login-border {
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
  background-color: #fff;
  width: 50%;
  float: left;
  box-sizing: border-box;
}
.login-main {
  margin: 0 auto;
  width: 65%;
  box-sizing: border-box;
}
.login-main > h3 {
  margin-bottom: 20px;
}
.login-main > p {
  color: #76838f;
}
.login-title {
  color: #76838f;
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  letter-spacing: 4px;
}
.login-menu {
  margin-top: 40px;
  width: 100%;
  text-align: center;
  a {
    color: #999;
    font-size: 12px;
    margin: 0px 8px;
  }
}
.login-submit {
  width: 100%;
  height: 45px;
  border: 1px solid #409eff;
  background: none;
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 300;
  color: #409eff;
  cursor: pointer;
  margin-top: 30px;
  font-family: "neo";
  transition: 0.25s;
}
.login-form {
  margin: 10px 0;
  i {
    color: #333;
  }
  .el-form-item__content {
    width: 100%;
  }
  .el-form-item {
    margin-bottom: 12px;
  }
  .el-input {
    input {
      padding-bottom: 10px;
      text-indent: 5px;
      background: transparent;
      border: none;
      border-radius: 0;
      color: #333;
      border-bottom: 1px solid rgb(235, 237, 242);
    }
    .el-input__prefix {
      i {
        padding: 0 5px;
        font-size: 16px !important;
      }
    }
  }
}
.login-code {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 0 0 10px;
}
.login-code-img {
  margin-top: 2px;
  width: 100px;
  height: 38px;
  background-color: #fdfdfd;
  border: 1px solid #f0f0f0;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 5px;
  line-height: 38px;
  text-indent: 5px;
  text-align: center;
}
</style>
